import './App.css';
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Banner from './components/Banner/Banner'
import About from './components/Banner/About'
import Stack from './components/Stack/Stack'
import Lighting from './components/Lighting/Lighting';
import Spectra from './components/Spectra/Spectra'
import MProp from './components/MProp/MProp';
import * as Consts from "./components/Utils/Constants";
import Appearance from './components/Lighting/Appearance';

function App() {
  const backendURL = "https://www.spectrumcalculator.com/cal/"
  //const backendURL = "http://localhost:34568/"
  const [materials, setMaterials] = useState(Consts.defaultMaterials);
  const [materialForSelect, setMaterialForSelect] = useState(Consts.defaultMaterials.map((i) => { return { label: (i.name + rangeStr(i.range)), value: i.id }; }));
  const [stack, setStack] = useState(Consts.defaultStack);
  const [materialPlot, setMaterialPlot] = useState({ id: 0 });
  const [calcPara, setCalcPara] = useState(Consts.defaultCalcPara);
  const [spec, setSpec] = useState(Consts.defaultSpectrum);

  useEffect(() => {
    const getMaterials = async () => {
      const data = await fetchMaterials();  //read materials from backend server.
      setMaterials(data);
      setMaterialForSelect(data.map((i) => { return { label: (i.name + rangeStr(i.range)), value: i.id }; }));
      if (localStorage.getItem("stack")) setStack(JSON.parse(localStorage.getItem("stack")));
      if (localStorage.getItem("calcPara")) setCalcPara(JSON.parse(localStorage.getItem("calcPara")));
      if (localStorage.getItem("spec")) setSpec(JSON.parse(localStorage.getItem("spec")));
    }
    getMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function rangeStr(range) { //generate string represents the wavelength range
    let scale = 1;
    let unit = "nm";
    if (range[0] < 1000 && range[1] < 5000) {
    }
    else if (range[0] < 1000000) {
      scale = 1000;
      unit = "\u03BCm";
    }
    else {
      scale = 1000000;
      unit = "mm";
    }
    range = range.map(v => parseFloat((v / scale).toPrecision(2)));
    return ", " + range[0] + "~" + range[1] + unit;
  }

  //fetch available materials from server.
  const fetchMaterials = async () => {
    const ret = await fetch(backendURL + "materials?list");
    const data = await ret.json();
    return data;
  }

  //fetch material properties from server.
  const fetchMProp = async (m) => {
    const ret = await fetch(backendURL + "materials?" + m); //`http://localhost/materials?${m}`
    const data = await ret.json();
    if (data.RIi.length === 1) {
      data.RIi = [data.RIi[0], data.RIi[0]];
      data.RIr = [data.RIr[0], data.RIr[0]];
    }
    return data;
  }

  //send calculation parameters, wait and receive calculated spectrum from server.
  const fetchSpectrum = async () => {
    const ret = await fetch(backendURL + "calc", {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(getCalcPara())
    });
    const data = await ret.json();
    console.log(data);
    if ("error" in data) {
      alert("Error: " + data.error);
      setSpec({...spec, isValid:true});
    }
    else {
      data.isValid = true;
      setSpec(data);
      localStorage.setItem("calcPara", JSON.stringify(calcPara));
      localStorage.setItem("stack", JSON.stringify(stack));
      localStorage.setItem("spec", JSON.stringify(data));
    }
  }

  function getCalcPara() {
    let ret = { ...calcPara };
    let newStack = [];
    newStack.push(stack[0]);
    newStack.push(stack[stack.length - 1]);
    for (let i = stack.length - 2; i > 0; i--) newStack.push(stack[i]);
    ret.mStack = newStack.map((m) => m.material);
    ret.mThick = newStack.map((m) => m.thickness);
    ret.mStackSelIdx = [];
    for (let i = 2; i < newStack.length; i++) if (newStack[i].checked) ret.mStackSelIdx.push(i);
    return ret;
  }

  function onCalculate() {
    //Temperarily show "calculating..." before result from backend server comes back.
    if (calcPara.nStepAngle === 1) {
      console.log("Incident angle (deg) = " + calcPara.incidentAngle);
    }
    else {
      console.log("NA=" + calcPara.NA + ", " + calcPara.distribution + " distribution");
    }
    for (let i = 1; i < stack.length - 1; i++) {
      if (stack[i].material === 0) {
        console.log("Incomplete material stack selection, calculation request not sent.");
        alert("Please complete material coating stack selection before calculation~~");
        return;
      }
      if (stack[i].thickness === 0) {
        console.log(stack[i].material + "has zero thickness, calculation request not sent.");
        alert("Thickness of Layer " + (stack.length - i - 1) + " in the coating stack is ZERO. I don't think it makes sense. Sorry~~");
        return;
      }
    }

    setSpec({...spec, isValid:false});
    console.log("Calculate...");

    fetchSpectrum();
    
  }

  return (
    <div className="grid-container App">
      <Router>
        <Banner calcPara={calcPara} setCalcPara={setCalcPara} />
        <Route path='/about' component={About} />
        <Lighting calcPara={calcPara} setCalcPara={setCalcPara} onCalculate={onCalculate} spec={spec} />
        <Appearance spec={spec} calcPara={calcPara} />
        <Spectra spec={spec} calcPara={calcPara} />
        <Stack stack={stack} setStack={setStack} setMaterialPlot={setMaterialPlot} mat={materials} mSel={materialForSelect} setMSel={setMaterialForSelect} fetchMProp={fetchMProp} />
        <MProp materialPlot={materialPlot} setMaterialPlot={setMaterialPlot} mat={materials} mSel={materialForSelect} fetchMProp={fetchMProp} />
      </Router>
    </div>
  );
}

export default App;
