import Select from 'react-select';
import Plot2d from '../Utils/Plot2d';
import ReactHtmlParser from 'react-html-parser'
import './MProp.css'

const MProp = ({materialPlot, setMaterialPlot, mat, mSel, fetchMProp}) => {
    //const mSel = Consts.materials.map((item, idx) => {return {value: idx, label:item.name};});

    const setMPlot = async(material) =>{
        //fetch available materials from server.
        let data=await fetchMProp(material);
        setMaterialPlot(data);
    };

    const plotMaterial = ()=>{
        const data=[
            {
                x:materialPlot.x,
                y:materialPlot.RIr,
                name:"n"
            },
            {
                x:materialPlot.x,
                y:materialPlot.RIi,
                name:"\u03BA"
            }
        ];
        let l=materialPlot.xUnit.slice(-1);
        let xLabel = "";
        if (l==="m" || l==="A"){
            xLabel = "wavelength / ";
        }
        else if (l==="z") {
            xLabel = "frequency / ";
        }
        else if (l==="V" || l==="J"){
            xLabel = "energy / ";
        }
        xLabel += materialPlot.xUnit;
        let titleStr = materialPlot.source;

        const layout = {
            title: {
                text:titleStr
            },
            xaxis: {
                title: {
                    text: xLabel,
                    font: {
                        // family: 'Times',
                        color: '#7f7f7f'
                    }
                },
            },
            yaxis: {
                title: {
                    text: 'Refractive Index',
                    font: {
                        color: '#7f7f7f'
                    }
                }
            }
        };
        return (<Plot2d series = {data} layout = {layout}/>);
    };

    const replaceCrystalOrient = (str)=>{ //replace the crystal orientation sign such as <100> to (100), so react-html-parser won't create error.
        let s=str.split('');
        let n=s.length;
        for (let i=0; i<n; i++){
            if (s[i]==='<'){
                let j=i+1;
                while(j<n && s[j]<='9' && s[j]>='0') j++;
                if (j===n || j-i<3 || j-i>6 || s[j]!=='>') continue;
                s[i]='('; s[j]=')';
                i=j;
            }
        }
        return s.join('');
    }

    const additionalInfo = ()=>{ //show additional info below material RI drawing
        return (<p>
            { materialPlot.comm.length>2 && ReactHtmlParser("<b>Comment:</b> " + replaceCrystalOrient(materialPlot.comm) + "<br/>") } 
            { materialPlot.refer.length>2 && ReactHtmlParser("<b>Reference:</b> " + replaceCrystalOrient(materialPlot.refer) + "<br/>")}
            </p>
            );
    }
    return (
        <div className="grid-item app-mprop">
            <h1>Refractive Index (n + i{"\u03BA"}) of Materials</h1>
            <table className="mprop-header"><tbody><tr>
                <td>Choose a material:</td>
                <td><Select options={mSel} value={mSel.find(obj=>obj.value===materialPlot.id)} onChange={e=>setMPlot(e.value)} placeholder="Type a material name to select from more than 1000 entries..."/></td>
                <td></td>
                {/* <td><button onClick={()=>{}}>Import...</button></td> */}
            </tr>
            <tr><td colSpan="3">
            { (materialPlot.id!==0)? plotMaterial() : " "}
            </td></tr><tr><td colSpan="3">
            { (materialPlot.id!==0)? additionalInfo() : " "}
            </td></tr>
            </tbody></table>
        </div>
    );
};

export default MProp
