import {Link} from 'react-router-dom'
import imgRefl from "./icons/header_reflection.png";
import imgTran from "./icons/header_transmission.png";
import "./Banner.css"

const Banner = ({calcPara, setCalcPara}) => {
    const imgSrc = calcPara.reflect? imgRefl:imgTran;
    return (
        <div className="grid-item app-header">
        {/* <header> */}
            <table><tbody><tr>
                <td style = {{width:"10%"}}>
                    <img src={imgSrc} alt="tag, transmission or reflection" style={{height:"10vh",textAlign:"left"}}/>
                </td>
                <td style = {{width:"80%"}}><h1>
                    <button key="reflTranBtn" onClick={()=>setCalcPara({...calcPara, reflect:!calcPara.reflect})}>
                        {calcPara.reflect ? "Reflection" : "Transmission"}
                    </button>
                     Spectrum Calculator</h1>
                </td>
                <td style = {{backgroundColor:"darkslategray"}}>
                    <Link to="/about">About</Link>
                </td>
            </tr></tbody></table>
        {/* </header> */}
        </div>
    )
}

export default Banner
