import { useEffect, useRef } from "react";
import "./Lighting.css"

function Lighting({ calcPara, setCalcPara, onCalculate, spec }) {
    const btn_calc = useRef(null);
    function onSubmit(e) {
        e.preventDefault();
        onCalculate();
    }
    function lightCond(isParaRay) {
        return (
            <form className="form" onSubmit={onSubmit}>
                {isParaRay === 1 ?
                    <div className="form-control">
                        <br />
                        <label>Incident angle (deg):</label>
                        <div className="container box1">
                            <input type="number" placeholder="angle in degrees" value={calcPara.incidentAngle}
                                step="0.1" min="0" max="90"
                                onChange={(e) => setCalcPara({ ...calcPara, incidentAngle: +e.target.value })} /></div>
                        <br /><br /><br /><br />
                    </div>
                    :
                    <div className="form-control">
                        <br />
                        <label>Numeric aperture (NA): </label>

                        <div className="container box1">
                            <input type="number" placeholder="NA" value={calcPara.NA}
                                step="0.01" min="0.01" max="1"
                                onChange={(e) => setCalcPara({ ...calcPara, NA: +e.target.value })} /></div>
                        <label htmlFor="distribution">Angle distribution: </label>
                        <br />
                        <div className="container box1">
                            <select name="distribution" id="distribution"
                                value={calcPara.distribution}
                                onChange={(e) => setCalcPara({ ...calcPara, distribution: e.target.value })}>
                                <option value="uniform" >Uniform</option>
                                <option value="gaussian" >Gaussian</option>
                            </select></div>
                        <br /><br />
                    </div>
                }
                <button className="btn btn-calculate" type="submit" ref={btn_calc}>Calculate Spectrum →→</button>
            </form>
        );
    }
    useEffect(() => {
        if (btn_calc.current) {
            btn_calc.current.innerHTML = spec.isValid ? "Calculate Spectrum →→" : "Calculating...";
            btn_calc.current.disabled = !spec.isValid;
            btn_calc.current.style.backgroundImage = spec.isValid ? "linear-gradient(to right, red, orange,yellow,green,dodgerblue,violet)" : "linear-gradient(to right, gray, gray)";
        }
    }, [spec.isValid]);
    return (
        <div className="grid-item app-light-cond" >
            <h2>Lighting:</h2>
            <button className="btn btn-light" onClick={() => setCalcPara({ ...calcPara, nStepAngle: 1 - calcPara.nStepAngle })}>{
                calcPara.nStepAngle === 1 ? "Collimated (Parallel)" : "Converging (Microscope)"
            }</button>
            {lightCond(calcPara.nStepAngle)}
        </div>
    )
}

export default Lighting
