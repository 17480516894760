//import React from 'react';
//import { useState } from 'react';
import Plot2d from '../Utils/Plot2d'

function Spectra({ spec, calcPara }) {
  var series = [];
  var layout = {};
  if (spec.isValid) {
    if (calcPara.incidentAngle === 0 || calcPara.nStepAngle !== 1) {//no polarization needed
      series = [
        {
          name: "Substrate",
          y: "reflPowerP" in spec ? spec.reflPowerP[spec.reflPowerP.length - 1] : spec.tranPowerP[spec.tranPowerP.length - 1],
          x: spec.x,
        },
        {
          name: "All layers",
          y: "reflPowerP" in spec ? spec.reflPowerP[0] : spec.tranPowerP[0],
          x: spec.x,
        }
      ];
    }
    else {//need to display polarization dependence
      series = [
        {
          name: "Substrate p-pol",
          y: "reflPowerP" in spec ? spec.reflPowerP[spec.reflPowerP.length - 1] : spec.tranPowerP[spec.tranPowerP.length - 1],
          x: spec.x,
        },
        {
          name: "Substrate s-pol",
          y: "reflPowerS" in spec ? spec.reflPowerS[spec.reflPowerS.length - 1] : spec.tranPowerS[spec.tranPowerS.length - 1],
          x: spec.x,
        },
        {
          name: "All layers p-pol",
          y: "reflPowerP" in spec ? spec.reflPowerP[0] : spec.tranPowerP[0],
          x: spec.x,
        },
        {
          name: "All layers s-pol",
          y: "reflPowerS" in spec ? spec.reflPowerS[0] : spec.tranPowerS[0],
          x: spec.x,
        },
      ];
    }
    if ((("reflPowerS" in spec) && (spec.reflPowerS.length === 3)) || (("tranPowerS" in spec) && (spec.tranPowerS.length === 3))) { //has selected layer info
      if (calcPara.incidentAngle === 0 || calcPara.nStepAngle !== 1) { //no polarization needed
        if ("reflPowerS" in spec) {
          series.push({
            name: "Sel. layers",
            y: spec.reflPowerP[1],
            x: spec.x
          });
        }
        else {
          series.push({
            name: "Sel. layers",
            y: spec.tranPowerP[1],
            x: spec.x
          });
        }
      }
      else { //need to display polarization dependence
        if ("reflPowerS" in spec) {
          series.push({
            name: "Sel. layers p-pol",
            y: spec.reflPowerP[1],
            x: spec.x
          });
          series.push({
            name: "Sel. layers s-pol",
            y: spec.reflPowerS[1],
            x: spec.x
          });
        }
        else {

          series.push({
            name: "Sel. layers p-pol",
            y: spec.tranPowerP[1],
            x: spec.x
          });
          series.push({
            name: "Sel. layers s-pol",
            y: spec.tranPowerS[1],
            x: spec.x
          });

        }
      }
    }
    layout = {
      title: {
        text: "Calculated Spectrum"
      },
      xaxis: {
        title: {
          text: "wavelength / nm",
          font: {
            // family: 'Times',
            color: '#7f7f7f'
          }
        },
      },
      yaxis: {
        title: {
          text: "reflPowerS" in spec ? "Reflectivity" : "Transmission",
          font: {
            color: '#7f7f7f'
          }
        }
      }
    };
  }
  else{
    series=[];
    layout={title:{text:"Calculating..."}};
  }


  return (
    <div className="grid-item app-spectra">
      <Plot2d series={series} layout={layout} />
    </div>
  );
}
export default Spectra
