import { useState } from 'react'
import './Appearance.css'
function Appearance({ spec, calcPara }) {
    //console.log(colors);
    const [illu, setIllu] = useState(51);
    const [pol, setPol] = useState(50);
    let clip = 0;
    function showPol() { //should show polarization or not
        if (calcPara.incidentAngle === 0 || calcPara.nStepAngle !== 1) return false;
        return true;
    }

    function getColor(c) { //c0[x,y,z] is p-pol, c1[x,y,z] is s-pol
        let c0 = c[0].map(i => i * (100 - pol) / 100);
        let c1 = c[1].map(i => i * pol / 100);
        c0[0] *= 0.78; c1[0] *= 0.78; //this is temperary - the calculated color is reddish. reduce red to 78% makes the color neutral.
        let hasClipping = false;
        for (let i = 0; i < c0.length; i++) {
            c0[i] += c1[i];
            c0[i] *= Math.pow((illu + 9) / 60, 3);
            //gamma correction
            c0[i] = ((c0[i] > 0.0031308) ? (1.055 * Math.pow(c0[i], 1 / 2.4) - 0.055) : (12.92 * c0[i]));
            //clipping
            if (c0[i] < 0) c0[i] = 0;
            else if (c0[i] >= 1) { c0[i] = 0.9999; hasClipping = true; }
            //convert to 8bit color
            c0[i] = Math.floor(c0[i] * 256);
        }
        if (hasClipping) clip++;
        let res = "rgb(" + c0.join(",") + ")";
        //console.log(res);
        return res;

        //A better way that could take monitor's color profile in consideration is to directly use xyz value. 
        //However, this belongs to CSS Color Module Level 4, not implemented yet (latest: W3C Draft done June 2021)
        //return "color(xyz 0.56 0.30 0.23)";  
    }
    return (
        <div className="grid-item app-appear" style={{ backgroundColor: getColor(spec.colors[spec.colors.length - 1]) }}>
            <table style={{ borderCollapse: "collapse" }}><tbody><tr>
                <td style={{ width: "30%", textAlign: "left" }}>
                    illumination
                </td><td style={{ width: "20%", textAlign: "center" }}>
                    <input type="range" min="1" max="101" value={illu}
                        onChange={(e) => setIllu(e.target.valueAsNumber)}
                        onDoubleClick={(e) => setIllu(50)}
                        className="slider-appear" id="illSlider" style={{ width: "100%" }} />
                </td>

                <td style={{ width: "2%", textAlign: "left", color: "black" }}><b>{clip ? "!" : ""}</b></td>

                <td>
                    <h2 style={{ fontSize: "var(--fontSize3)", margin: "0.1vw" }}>{spec.colors[0][0][3] < 0.99 ? "Visual color* of:" : "Visual color of :"}</h2>
                </td></tr>
                <tr>
                    <td style={{ textAlign: "left" }}>
                        {showPol() && "polarization"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                        {showPol() && "p"}
                        {showPol() && <input type="range" min="0" max="100" value={pol}
                            onChange={(e) => setPol(e.target.valueAsNumber)}
                            onDoubleClick={(e) => setPol(50)}
                            className="slider-appear" id="polSlider" style={{ width: "60%" }} />}
                        {showPol() && "s"}
                    </td><td></td><td>
                        Substrate
                    </td>
                </tr>
                <tr>
                    <td>
                    </td>
                    <td style={{ textAlign: "center" }}>
                        <br />
                    </td><td>
                    </td>
                </tr>

            </tbody></table>
            <div style={(spec.colors.length === 3) ? { border: "none", backgroundColor: getColor(spec.colors[1]) } : { border: "none" }}>
                {(spec.colors.length === 3) ? "Selected coating" : ""}<br /><br />
                <div style={{ border: "none", backgroundColor: getColor(spec.colors[0]) }}>
                    {spec.colors.length === 3 ? "Entire Coating" : "Coated Area"}<br /><br /><br /><br />
                </div>
            </div>

            <table><tbody>
                {spec.colors[0][0][3] < 0.99 && <tr><td style={{ fontSize: "var(--fontSize4)", verticalAlign: "super", width: "7%" }}><b>* :</b></td>
                    <td style={{ fontSize: "var(--fontSize5)" }}>The calculated spectrum do not cover the entire visible range. Color may be inaccurate.</td></tr>}
                {clip>0 && <tr><td style={{ fontSize: "var(--fontSize4)", verticalAlign: "super", width: "7%", color: "black" }}><b>! :</b></td>
                    <td style={{ fontSize: "var(--fontSize5)", color:"black" }}>The result has clipping. Color may be inaccurate.</td></tr>}
            </tbody>
            </table>
        </div>
    )
}

export default Appearance
