import { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";

const MultiRangeSlider = ({ min, max, rng, setRng, name }) => {
  const range = useRef(null);
  const leftTxt = useRef(null);
  const rightTxt = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );
  const getRoughPercent = useCallback(
    (value) => Math.ceil(((value * 0.9 + (max - min) * 0.025 - min) / (max - min)) * 25) * 4 - 1,
    [min, max]
  );

  // Set width of the range to change according to rng
  useEffect(() => {
    const toWaveLengthTxt = (val) => val === 0 ? "0" :
      val < 3 * 1000 ? Math.pow(10, val / 1000).toPrecision(3) + " nm" :
        val < 6 * 1000 ? Math.pow(10, val / 1000 - 3).toPrecision(3) + " \u03BCm" :
          Math.pow(10, val / 1000 - 6).toPrecision(3) + " mm";

    const minPercent = getPercent(rng[0]);
    const maxPercent = getPercent(rng[1]);
    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
    if (leftTxt.current) {
      leftTxt.current.style.left = `${getRoughPercent(rng[0]) - 9}%`;
      leftTxt.current.innerHTML = toWaveLengthTxt(rng[0]);
    }
    if (rightTxt.current) {
      rightTxt.current.style.left = `${getRoughPercent(rng[1])}%`;
      rightTxt.current.innerHTML = toWaveLengthTxt(rng[1]);
    }
  }, [rng, getPercent, getRoughPercent]);

  return (
    <div className="container-mrs">
      <input
        type="range"
        min={min}
        max={max}
        value={rng[0]}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), rng[1] - 1);
          setRng([value, rng[1]]);
        }}
        className="thumb thumb--left"
        style={{ zIndex: rng[0] > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={rng[1]}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), rng[0] + 1);
          setRng([rng[0], value]);
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        {name && <div className="slider__title">{name}</div>}
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div ref={leftTxt} className="slider__left-value">
          {"haha"}</div>
        <div ref={rightTxt} className="slider__right-value" >
          {"xixi"}</div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  rng: PropTypes.array.isRequired,
  setRng: PropTypes.func.isRequired,
};

export default MultiRangeSlider;