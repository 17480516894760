//import React, {useState} from 'react';
//import Plot from 'react-plotly.js';
import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import {withSize} from 'react-sizeme';

const Plot2d = ({series, layout}) => {
  const Plot=createPlotlyComponent(Plotly);
  return (
    <div><Plot data={series} layout={{...layout, autosize:true}} style={{width:"100%", height:"100%"}}/></div>
  );
};

export default withSize()(Plot2d);
//export default Plot2d;