export const units = [
    { id: "m", name: "m", scale: 1 },
    { id: "mm", name: "mm", scale: 1e-3 },
    { id: "um", name: "\u03BCm", scale: 1e-6 },
    { id: "nm", name: "nm", scale: 1e-9 },
    { id: "A", name: "\u212B", scale: 1e-10 }
];

export const defaultLayer = {
    material: 0,
    checked: false,
    thickness: 0.0,
    thickunit: "nm"
};

export const defaultMaterials = [
    { id: "Si_0B", name: "Silicon (SC, low doping)", alt: 0.0, range: [400.0, 900.0] }, 
    { id: "C_0U", name: "Graphene, RT", alt: 0.335e-9, range: [400.0, 900.0] }, 
    { id: "air_1A", name: "Air, 760 Torr, RT", alt: 0.0, range: [400.0, 900.0] }, 
    { id: "SiO2_0A", name: "SiO2, thermal oxide", alt: 0.0, range: [400.0, 900.0] }
];
export const defaultStack = [
    { ...defaultLayer, material: "air_1A" },
    { ...defaultLayer, material: "C_0U", thickness: 1.675e-9, thickunit: "ly" },
    { ...defaultLayer, material: "SiO2_0A", thickness: 300e-9, checked: true},
    { ...defaultLayer, material: "Si_0B" },
];

export const defaultCalcPara = {
    reflect: true,
    nStepWavelength: 200,
    wavelengthRange: [0, 0],
    incidentAngle: 30,
    NA: 0.1,
    nStepAngle: 1,
    distribution: "uniform"
};

export const defaultSpectrum = {
    xUnit: "nm",
    yUnit: "1",
    isValid: true,
    x: [
        250,
        265,
        280,
        295,
        310,
        325,
        340,
        355,
        370,
        385,
        400,
        415,
        430,
        445,
        460,
        475,
        490,
        505,
        520,
        535,
        550,
        565,
        580,
        595,
        610,
        625,
        640,
        655,
        670,
        685,
        700,
        715,
        730,
        745,
        760,
        775,
        790,
        805,
        820,
        835,
        850,
        865,
        880,
        895,
        910,
        925,
        940,
        955,
        970,
        985,
        1000
    ],
    reflPowerP: [
        [
            0.08804896374123083,
            0.30554048954622043,
            0.640127250688789,
            0.610865653694688,
            0.3898234008640829,
            0.20363637135083099,
            0.10592171999967878,
            0.14205878704876973,
            0.28723738640246815,
            0.37804273970315294,
            0.41503750732475775,
            0.41971727240410706,
            0.39589625705082265,
            0.3532481337067266,
            0.298585855932887,
            0.2394035405037132,
            0.1820693837599814,
            0.13108671174616252,
            0.09074394624820076,
            0.06314605624745423,
            0.04887293916033467,
            0.0463550222742549,
            0.05395453255325255,
            0.06938153525929393,
            0.08976204884261405,
            0.11308662206452695,
            0.13746367000654264,
            0.16141288786010874,
            0.18425770988954157,
            0.2049404535185686,
            0.223411576535874,
            0.2393279712125393,
            0.2527137217828567,
            0.2637214023543489,
            0.27228724491562073,
            0.2787631817799142,
            0.2832552571605743,
            0.2858275385740333,
            0.28678680805096646,
            0.28607939100950197,
            0.284452234096545,
            0.2812258525717156,
            0.2772800699548709,
            0.27265841655205086,
            0.26701885001963943,
            0.2608142840116462,
            0.2539435791707238,
            0.2467592495979172,
            0.23918828346227544,
            0.23212282483691543,
            0.22402942808677645
        ],
        [
            0.4330015671555351,
            0.5916956732084188,
            0.6893701424964644,
            0.6158716011017354,
            0.4639896389436938,
            0.3373355306371419,
            0.2848825865284528,
            0.3389513278302245,
            0.4346979908532558,
            0.43989688227659035,
            0.4272933533903048,
            0.40888314093960326,
            0.3788487397925524,
            0.34028737435316675,
            0.29519446536895994,
            0.24767204643025229,
            0.2023617288388887,
            0.16262616550189424,
            0.1323712354981872,
            0.1132747327149653,
            0.10566442543450597,
            0.10716869758240909,
            0.11617707703577149,
            0.13057930649728228,
            0.14753459569920674,
            0.1656895332846823,
            0.1836361833796266,
            0.20046571696110527,
            0.21599422031447527,
            0.22944157553396183,
            0.2411055946301302,
            0.25075801356530275,
            0.2585473481756616,
            0.2646968315254864,
            0.2691238786493359,
            0.2721717539837983,
            0.27390321547523866,
            0.2743296245308928,
            0.27371011134713197,
            0.2719237321793409,
            0.26967618089991235,
            0.26621036888049293,
            0.26237866186623293,
            0.25817404601192057,
            0.253193942864231,
            0.24786701239895156,
            0.24204143366379433,
            0.23605246521900852,
            0.22979350113234848,
            0.2242029311466408,
            0.21756464232083286
        ],
        [
            0.6289355984716707,
            0.6853244377284768,
            0.6926887359634067,
            0.6234598275826497,
            0.5485178455869477,
            0.5210340477833088,
            0.512473899707876,
            0.5249855058520799,
            0.5383829075248108,
            0.4826152141881179,
            0.4372778815778509,
            0.4088565397374453,
            0.38728566161345956,
            0.37126959631941225,
            0.3584042283272391,
            0.34784698057735647,
            0.3393618344182453,
            0.33160958445455174,
            0.32492948993941845,
            0.3191021728258278,
            0.3144529555890202,
            0.3096471615067292,
            0.3054268011924087,
            0.30213510116821907,
            0.2988187331331506,
            0.2959515804460555,
            0.2932842305775318,
            0.2908105027526045,
            0.2887667198385024,
            0.2866207479400451,
            0.28480070459295764,
            0.2829983921970432,
            0.28130187143229496,
            0.27984449015217017,
            0.2783825380158798,
            0.277112196052695,
            0.27595260640227603,
            0.274777449870522,
            0.27371411021961295,
            0.27251422416564647,
            0.2717723177789542,
            0.27062527654298696,
            0.2698226169782087,
            0.269269594646345,
            0.26848401134091887,
            0.2678197721069683,
            0.2670382930710238,
            0.2664173747534463,
            0.2657956627072658,
            0.26610660337430775,
            0.26548449891475207
        ]
    ],
    reflPowerS: [
        [
            0.044509161433329496,
            0.3455663350230691,
            0.7144970957735612,
            0.6812676128083593,
            0.44145492965238886,
            0.20756212623718873,
            0.06826066776753362,
            0.1180279099885542,
            0.3203543381489862,
            0.45436824119177266,
            0.5104772674065826,
            0.5217179291033927,
            0.49729057727518705,
            0.4482427799574938,
            0.38159995049955686,
            0.3053406218291052,
            0.22722420425065915,
            0.15430916717374557,
            0.09419035640332049,
            0.05205483635325525,
            0.030301801997791818,
            0.027804883086781293,
            0.04177767674557281,
            0.06802202001214232,
            0.10174019054973654,
            0.13917119285258805,
            0.17722904717186533,
            0.21365310322104436,
            0.2474578522565301,
            0.27745753657064864,
            0.30367002520417663,
            0.32590239199661725,
            0.3443435299649762,
            0.35931002930797307,
            0.37089365405186603,
            0.3795927187267694,
            0.38562422368131605,
            0.3891421232495592,
            0.3905345655837491,
            0.38980323619998114,
            0.3877660525439827,
            0.3837521756085116,
            0.37870487667820857,
            0.372693434901325,
            0.36537711352877295,
            0.35724458540647697,
            0.34820882967985367,
            0.33865315298057985,
            0.32850962701643627,
            0.3187295040425662,
            0.30772075187257836
        ],
        [
            0.4148793339955707,
            0.6440666388424028,
            0.7567021016548025,
            0.6906602108632223,
            0.5253971470263372,
            0.34782574221790424,
            0.2667791270838669,
            0.35713992366164676,
            0.496899392498189,
            0.5263514728156252,
            0.5250659152682451,
            0.5106208323584733,
            0.4793925153120394,
            0.4344371370830752,
            0.3772757471827849,
            0.31211831675162904,
            0.24509849299209074,
            0.18272183350741156,
            0.13317848847790878,
            0.10183826419668304,
            0.09066557182749162,
            0.09665289563290357,
            0.11594013977877332,
            0.14376975202421277,
            0.17508828438567067,
            0.2070395908641054,
            0.23740328169618552,
            0.2649087382265112,
            0.2894037014028316,
            0.3101885128483448,
            0.32777824371684705,
            0.34214794686837824,
            0.35363387794177925,
            0.3626196302602383,
            0.36915262453143644,
            0.37369722710003267,
            0.37639941425045675,
            0.37733378860255146,
            0.37681984046825534,
            0.37476920402292363,
            0.37194854200989647,
            0.3675851942751706,
            0.36259346148025984,
            0.35698162708794323,
            0.3503324245162644,
            0.34310933493363704,
            0.33516392702117376,
            0.3268624827710224,
            0.3180963386122895,
            0.30988382666303627,
            0.3003925344780496
        ],
        [
            0.7082685031823632,
            0.7545150225252492,
            0.7599751631973763,
            0.7019573232165718,
            0.6375249781925648,
            0.6133333457350386,
            0.6056894987521892,
            0.6167046748581062,
            0.6283159901738208,
            0.578609045080991,
            0.5371484636785457,
            0.510594580438853,
            0.4901216283457205,
            0.474729653073298,
            0.4622406456071173,
            0.4519052638097655,
            0.44353932358997866,
            0.4358484330575381,
            0.42918369153171,
            0.4233406324961744,
            0.41865898993344247,
            0.41380075672686095,
            0.4095181606560089,
            0.40616724170955937,
            0.40278161258913775,
            0.39984671628673696,
            0.3971096909188912,
            0.3945655702689879,
            0.3924593786049249,
            0.3902437064459426,
            0.3883611686947206,
            0.38649388872055446,
            0.3847333891861011,
            0.38321884368182046,
            0.38169748826641825,
            0.38037384252380346,
            0.37916421952322105,
            0.3779370103588531,
            0.3768253954599516,
            0.3755696873180091,
            0.37479254641287746,
            0.3735899452069403,
            0.3727476190124026,
            0.3721668880064796,
            0.3713414111993404,
            0.370642950918203,
            0.36982063445960295,
            0.3691668233325376,
            0.36851177976721017,
            0.36883944022134924,
            0.36818378417367387
        ]
    ],
    colors:[
        [
            [
                0.04196923928607084,
                0.02705065942943812,
                0.13503749067703147,
                1
            ],
            [
                0.046442297562900886,
                0.024917971920547736,
                0.17180582894560034,
                1
            ]
        ],
        [
            [
                0.0723636266723174,
                0.04625803478359443,
                0.12986115543674964,
                1
            ],
            [
                0.0858352714627616,
                0.0447800889968489,
                0.16593259187130577,
                1
            ]
        ],
        [
            [
                0.14413044907809286,
                0.12351862569438951,
                0.14036414466391017,
                1
            ],
            [
                0.19523236949664377,
                0.1640190934037181,
                0.1786833087649027,
                1
            ]
        ]
    ]
};
