import { useState } from 'react'
import Select from 'react-select';
import * as Consts from "../Utils/Constants.js"
import './Stack.css';
import plotButtImg from "../resources/plot.png"
import MFilter from './MFilter.js';
// import { MaterialSlider } from 'react-multi-thumb-slider';

function Stack({ stack, setStack, setMaterialPlot, mat, mSel, setMSel, fetchMProp }) {//mat: list of materials; mSel: materials in <Select />; 

    const [showFilter, setShowFilter] = useState(false);
    const [isFilterOn, setIsFilterOn] = useState(false);
    const units = Consts.units.map((i) => { return { value: i.id, label: i.name }; });
    const insertLayerAbove = () => {
        let newStack = [...stack];
        let firstSelIdx = 0;
        for (let i = 1; i < stack.length - 1; i++) {
            if (stack[i].checked) {
                firstSelIdx = i;
                break;
            }
        }
        if (firstSelIdx === 0) firstSelIdx = 1;
        newStack.splice(firstSelIdx, 0, { ...Consts.defaultLayer });
        setStack(newStack);
    }
    const insertLayerBelow = () => {
        let newStack = [...stack];
        let lastSelIdx = 0;
        for (let i = stack.length - 2; i >= 0; i--) {
            if (stack[i].checked) {
                lastSelIdx = i + 1;
                break;
            }
        }
        if (lastSelIdx === 0) lastSelIdx = stack.length - 1;
        newStack.splice(lastSelIdx, 0, { ...Consts.defaultLayer });
        setStack(newStack);
    }

    const duplicateSelected = () => {
        let dupl = [];
        let firstSelIdx = 0;
        for (let i = 1; i < stack.length - 1; i++) {
            if (stack[i].checked) {
                let stackCopy = { ...stack[i] };
                stackCopy.checked = false;
                dupl.push(stackCopy);
                if (firstSelIdx === 0) firstSelIdx = i;
            }
        }
        if (dupl.length === 0) return;
        let newStack = [...stack];
        for (let i = dupl.length - 1; i >= 0; i--) {
            newStack.splice(firstSelIdx, 0, dupl[i]);
        }
        setStack(newStack);
    }

    const deleteLayer = () => {
        setStack(stack.filter(item => item.checked === false));
    };

    const setMaterial = (idx, value) => {
        let newStack = [...stack];
        newStack[idx].material = value;
        setStack(newStack);
    };
    const setThickness = (idx, e) => {
        let newStack = [...stack];
        newStack[idx].thickness = e * getUnitScale(newStack[idx].thickunit, newStack[idx].material);
        setStack(newStack);
    }
    const setThicknessUnit = (idx, unit) => {
        let newStack = [...stack];
        newStack[idx].thickness = newStack[idx].thickness / getUnitScale(newStack[idx].thickunit, newStack[idx].material) * getUnitScale(unit, newStack[idx].material);
        newStack[idx].thickunit = unit;
        setStack(newStack);
    }
    const setChecked = (idx, value) => {
        let newStack = [...stack];
        newStack[idx].checked = value;
        setStack(newStack);
    };
    const setCheckedAll = (checked) => {
        let newStack = [...stack];
        for (let i = 1; i < newStack.length - 1; i++) {
            newStack[i].checked = checked;
        }
        setStack(newStack);
    };
    const getAvailableUnits = (material) => { //only for layered materials such as graphene, units selection has "layers" option available.
        return (material === 0 || mat.find(obj => obj.id === material).alt === 0.0) ? units : [...units, { value: "ly", label: "layers" }];
    };
    const getUnitScale = (unitID, matID) => {
        if (unitID === "ly") return mat.find(m => m.id === matID).alt;
        return Consts.units.find(u => u.id === unitID).scale;
    }
    const setMPlot = async (idx) => {
        if (stack[idx].material === 0) {
            console.log("no material chosen yet.");
            return;
        }
        let data = await fetchMProp(stack[idx].material);
        setMaterialPlot(data);
    }

    const roundNum = (num, maxPrecision = 4) => {
        //const maxPrecision=4;
        let a = num.toPrecision(maxPrecision);
        return parseFloat(a);
    }

    const renderLayers = () => {
        return stack.map((item, idx) => {
            const name = (idx === 0) ? ("Environment") : (idx === stack.length - 1 ? "Substrate" : `Layer ${stack.length - idx - 1}`);
            const halfRender = (idx === 0 || idx === stack.length - 1);
            const availUnit = getAvailableUnits(item.material);
            const setUnit = ()=>{
                let r=availUnit.find(obj => obj.value === item.thickunit);
                if (r===undefined) {
                    setThicknessUnit(idx,"nm");
                    return availUnit.find(obj => obj.value === "nm");
                }
                return r;}

            return (
                <tr key={idx}>
                    <td>{name}</td>
                    <td><button onClick={() => setMPlot(idx)}><img src={plotButtImg} alt="P" /><span className="tiptext">Plot in <b>Refractive Index</b> section</span></button></td>
                    <td><Select options={mSel} value={item.material === 0 ? null : mSel.find(obj => obj.value === item.material)} onChange={e => setMaterial(idx, e.value)} placeholder="Type a name to select..."/></td>
                    {!halfRender && <td>
                    <input type="number" style = {{
                        fontSize:"var(--fontSize4)", padding:"7px", borderRadius:"4px"}} 
                        value={roundNum(item.thickness / getUnitScale(item.thickunit, item.material))} min="0" 
                        onChange={e => { e.preventDefault(); setThickness(idx, e.target.value) }} /></td>}
                    {!halfRender && <td>
                        <Select options={availUnit} value={setUnit()} onChange={e => setThicknessUnit(idx, e.value)} /></td>}
                    {!halfRender && <td>
                        <input type="checkbox" checked={item.checked} onChange={e => setChecked(idx, e.target.checked)} /></td>}
                    
                </tr>
            );
        });
    }

    return (
        <div className="grid-item app-stack">
            <h1>Material Coating Stack</h1>

            <table className="stack-action"><tbody>
                <tr><td><button onClick={insertLayerAbove}>Insert Above</button></td>
                    <td><button onClick={insertLayerBelow}>Insert Below</button></td>
                    <td><button onClick={deleteLayer}>Delete</button></td>
                    <td><button onClick={duplicateSelected}>Duplicate</button></td></tr>
            </tbody></table>

            <table className="stack-header" style={{ color: "azure" }}><tbody><tr>
                <td></td>
                <td></td>
                <td>Material
                    <button
                        style={isFilterOn ? { background: "rgb(18, 78, 28)", color: "azure" } : { background: "rgb(54, 54, 54)", color: "gray" }}
                        onClick={() => setShowFilter(!showFilter)}>
                        Filter...
                    </button>
                </td>
                <td>Thickness</td>
                <td style = {{fontSize:"var(--fontSize4)",textAlign:"right"}}>Sel. all</td>
                <td><input type="checkbox" checked={stack.find(s => s.checked) !== undefined} onChange={e => setCheckedAll(e.target.checked)} /></td>
            </tr></tbody></table>

            <MFilter showFilter={showFilter} isFilterOn={isFilterOn} setFilterOn={setIsFilterOn} setMSel={setMSel} mat={mat} />

            <table className="stack-body"><tbody>
                {renderLayers()}
            </tbody></table>

        </div>
    )
}

export default Stack
