import {Link} from 'react-router-dom'
function About(){
  return (
    <div style={{gridColumn:"1/-1", justifySelf:"center", textAlign:"center", borderRadius: "1vw", backgroundColor:"azure", width:"70%", border:"1px solid lightblue", padding:"1.5vw"}}>
      <h2 style={{fontSize:"var(--fontSize0)", fontStyle:"italic"}}>"Where does color come from?"</h2>
      <h2 style={{fontSize:"var(--fontSize2)", fontStyle:"italic"}}>-- Issac Newton and everyone else</h2>
      <h2><br/></h2>
      <h3 style={{fontSize:"var(--fontSize2)"}}>Reflection / Transmission Spectrum Calculator</h3>
      <h3 style={{fontSize:"var(--fontSize2)"}}>& Color Simulator</h3>
      <h3 style={{fontSize:"var(--fontSize4)"}}><br/></h3>
      <h3 style={{fontSize:"var(--fontSize3)"}}> Qin Zhou and Jihua Li</h3>
      <h3 style={{fontSize:"var(--fontSize4)"}}> Questions? See <a href="http://spectrumcalculator.com/help/">help</a> or email admin at this site.</h3>
      <h3 style={{fontSize:"var(--fontSize3)"}}> Copyright &copy; 2021</h3>
      <h3 style={{fontSize:"var(--fontSize4)"}}> most refractive index data from <a href="https://refractiveindex.info/">refractiveindex.info</a></h3>
      <h3 style={{fontSize:"var(--fontSize4)"}}><br/></h3>
      <Link style={{
        fontSize:"var(--fontSize2)", 
        padding: "0.5vw 2vw", 
        margin:"0.3vw", 
        color:"lightgray", 
        textDecoration:"none", 
        border: "2px solid rgb(100, 250, 80)", 
        borderRadius: "1vw", 
        backgroundColor:"black"}} 
        to="/">
          Back
          </Link>
    </div>
  );
}

export default About;