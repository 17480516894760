import { useEffect, useState } from "react"
import MultiRangeSlider from "./MultiRangeSlider"
import "./mFilter.css"

const rwl = (wavelength) => Math.round(1000 * Math.log10(wavelength)); //wavelength->value in <input type="range">
const MFilter = ({ showFilter, isFilterOn, setFilterOn, setMSel, mat }) => {
  const [range, setRange] = useState([rwl(400), rwl(900)]); //range of wavelength for materials in <Select/>, if isFilterOn
  const [coverRange, setCoverRange] = useState(false); //if the materials in <Select/> should have data completely cover the selected range, or should a material containing data in range suffice. 
  useEffect(() => {
    function rangeStr(range) { //generate string represents the wavelength range
      let scale = 1;
      let unit = "nm";
      if (range[0] < 1000 && range[1] < 5000) {
      }
      else if (range[0] < 1000000) {
        scale = 1000;
        unit = "\u03BCm";
      }
      else {
        scale = 1000000;
        unit = "mm";
      }
      range = range.map(v => parseFloat((v / scale).toPrecision(2)));
      return ", " + range[0] + "~" + range[1] + unit;
    }
    const wr = [Math.pow(10,range[0]/1000), Math.pow(10,range[1]/1000)]; //convert to actual wavelength range
    if (isFilterOn) {
      if (coverRange) {
        let filteredM=mat.filter(m=>m.range[0]<wr[0] && m.range[1]>wr[1]);
        setMSel(filteredM.map(i => {
           return { label: (i.name + rangeStr(i.range)), value: i.id }; 
        }));
      }
      else {
        let filteredM=mat.filter(m=>m.range[1]>wr[0] && m.range[0]<wr[1]);
        setMSel(filteredM.map(i => {
          return { label: (i.name + rangeStr(i.range)), value: i.id }; 
       }));
      }
    }
    else {
      setMSel(mat.map((i) => { return { label: (i.name + rangeStr(i.range)), value: i.id }; }));
    }

  }, [isFilterOn, range, coverRange, mat, setMSel]);

  return (
    <div style={showFilter ? { display: "block" } : { display: "none" }} className="mfilter-container">
      <MultiRangeSlider
        min={0}
        max={7000}
        rng={range}
        setRng={setRange}
        name="Filter by wavelength range:"
      />
      <table className="mfilter-table" style={{ width: "100%" }}><tbody><tr>
        <td style={{ width: "8%", textAlign: "right" }}>Load:</td>
        <td style={{ width: "38%" }}>
          <button onClick={() => { setRange([rwl(400), rwl(760)]) }}>visible</button>
          <button onClick={() => { setRange([rwl(780), rwl(2500)]) }}>NIR</button>
          <button onClick={() => { setRange([rwl(3000), rwl(25000)]) }}>MIR</button>
          <button onClick={() => { setRange([rwl(100), rwl(400)]) }}>UV</button>
          <button onClick={() => { setRange([rwl(10), rwl(100)]) }}>EUV</button>
        </td>
        <td style={{ width: "32%" }}><input type="checkbox" checked={coverRange} onChange={(e) => setCoverRange(e.target.checked)}></input>Cover selected range</td>
        <td style={{ width: "20%" }}>
          <button
            style={isFilterOn ? { background: "rgb(18, 78, 28)", width: "12vw" } : { background: "rgb(22, 27, 41)", width: "12vw" }}
            onClick={() => { setFilterOn(!isFilterOn); }} >
            {isFilterOn ? "Filter ON" : "Apply Filter"}
          </button>
        </td></tr></tbody></table>
    </div>
  )
}

export default MFilter
